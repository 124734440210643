<template>
  <!-- eslint-disable -->
  <div>
      <sw-table
              table-id="87f2aa6a20f2484a8054a68561d07ba1"
              show-columns
              show-search
              :fields.async="userFields"
              :pagination="pagination"
              @change-pagination="Object.assign(pagination, $event)"
              @change-search-query="searchQuery = $event"
              @reload-content="getUsers"
              @set-fields="userFields = $event"
      >
          <template #table>
              <b-table
                      ref="selectableTable"
                      :items="contacts"
                      class="table-wrap-words"
                      :fields="fields"
                      :sort-by.sync="sorter.sortBy"
                      :sort-desc.sync="sorter.sortDesc"
                      :no-local-sorting="true"
                      @sort-changed="Object.assign(sorter, $event); getUsers()"
                      striped
                      responsive
                      :busy="loading"
                      show-empty
              >
                  <template #head()="{ label }">
                      {{ $t(`${label}`) }}
                  </template>
                <template #head(select)="{ label }">
                    <b-form-checkbox v-model="selectAll"
                                     @change="selectAllContacts"
                    />
                </template>
                <template #cell(select)="{ item }">
                    <b-form-checkbox v-model="selectedContacts" :value="item.id" />
                </template>
                  <template #cell(id)="{ index }">
                      #{{ getOrderNo(index, pagination, sorter) }}
                  </template>
                  <!-- FirstName -->
                  <template #cell(name)="{ item }">
                      <router-link class="text-nowrap" :class="{ 'deleted-text': item.deletedAt }" v-if="item.firstName || item.lastName" :to="`/contact/${item.id}`">
                          {{ getUserName(item) }}
                      </router-link>
                      <router-link v-else-if="item.id" :to="`/contact/${item.id}`" :class="{ 'deleted-text': item.deletedAt }">
                          <feather-icon icon="MinuIcon" />
                      </router-link>
                      <feather-icon v-else icon="MinuIcon" />
                  </template>
                  <template #cell(address)="{ item }">
                      <div class="text-nowrap">
                          {{ getAddress(item) }}
                      </div>
                  </template>
                  <template #cell(addressCorrespondence)="{ item }">
                      <div class="text-nowrap">
                          {{ getAddress(item, 'correspondence') }}
                      </div>
                  </template>
                  <!-- FirstName -->
                  <template #cell(phone)="{ value }">
                      <a :href="`tel:${value}`" class="text-nowrap" v-if="value">
                          {{ value | phoneFormat }}
                      </a>

                      <feather-icon
                              v-else
                              icon="MinusIcon"
                      />
                  </template>
                  <template #cell(email)="{ value }">
                      <a :href="`mailto:${value}`" class="text-nowrap" v-if="value">
                          {{ value }}
                      </a>

                      <feather-icon
                              v-else
                              icon="MinusIcon"
                      />
                  </template>
                  <!-- Tags -->
                  <template #cell(contactTypes)="{ value, index }">
                      <div
                              v-if="Array.isArray(value) && value.length"
                              class="mt-25"
                      >
                          <b-badge
                                  v-for="tag in value"
                                  :key="`tag_${tag.id}_${index}`"
                                  variant="light-secondary"
                                  class="mr-25"
                          >
                              <div class="d-flex">
                                  <div
                                          class="color-dot mr-25"
                                          :style="{ backgroundColor: tag.color || '#fff' }"
                                          style="border: 1px solid white"
                                  />
                                  {{ tag.name }}
                              </div>
                          </b-badge>
                      </div>
                      <feather-icon
                              v-else
                              icon="MinusIcon"
                      />
                  </template>
                  <template #cell(position)="{ value }">
                      <span v-if="value">
                        {{ $t(contactPositionTypeByValue(value)) }}
                      </span>
                      <feather-icon
                              v-else
                              icon="MinusIcon"
                      />
                  </template>
<!--                  <template #cell(organization)="{ item }">-->
<!--          <span v-if="item.organization">-->
<!--            <span v-if="item.organization.name">{{ item.organization.name }}</span>-->
<!--            <feather-icon-->
<!--                    v-else-->
<!--                    icon="MinusIcon"-->
<!--            />-->
<!--          </span>-->
<!--                      <feather-icon-->
<!--                              v-else-->
<!--                              icon="MinusIcon"-->
<!--                      />-->
<!--                  </template>-->
                  <template #cell(vatId)="{ item }">
                      <span v-if="item.organization && item.organization.vatId">
                        {{ item.organization.vatId }}
                      </span>
                      <feather-icon
                              v-else
                              icon="MinusIcon"
                      />
                  </template>
                  <!-- status -->
                  <template #cell(status)="{ value, index, item }">
                      <b-badge
                              :id="`status_row_${index}`"
                              class="cursor-pointer"
                              :style="{ backgroundColor: value && value.color ? value.color + ' !important' : '#fff' }"
                      >
                          {{ value && value.name ? value.name : '-' }}
                      </b-badge>
                      <b-popover
                              triggers="hover"
                              :target="`status_row_${index}`"
                              container="customizedTable"
                              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_STATUS_EDIT])"
                      >
                          <template #title>
              <span>
                {{ $t('funnel.ChangeStatus') }}
              </span>
                          </template>
                          <b-list-group flush>
                              <b-list-group-item
                                      v-for="status in $store.getters['typeSettings/geContactStatuses']"
                                      :key="`status__${status.id}_${index}`"
                                      class="cursor-pointer"
                                      :disabled="status.id === item.status.id"
                                      @click="onChangeContactStatus(status, item.id, index)"
                              >
                                  <div
                                          class="color-dot"
                                          :style="{backgroundColor: status.color}"
                                  />
                                  {{ status.name }}
                              </b-list-group-item>
                          </b-list-group>
                      </b-popover>
                  </template>

                  <template #cell(action)="{ item }">
                      <b-dropdown
                              id="dropdown-dropleft"
                              dropleft
                              no-caret
                              variant="flat"
                              v-if="checkRequiredPermissions([$roles.CONTACT_EDIT_DETAILS, $roles.CONTACT_DELETE])"
                      >
                          <template #button-content>
                              <feather-icon icon="MoreVerticalIcon" />
                          </template>
                          <b-dropdown-item v-if="checkRequiredPermissions([$roles.CONTACT_EDIT_DETAILS])" @click="$router.push(`/contacts/edit/${item.id}`)">
                              <dropdown-item-label icon="EditIcon"
                                               :label="$t('Edit')"
                          />
                          </b-dropdown-item>

                          <b-dropdown-item v-if="checkRequiredPermissions([$roles.CONTACT_DELETE])" @click="$emit('delete-item', item.id)">
                              <dropdown-item-label icon="TrashIcon" :label="$t('Delete')"/>
                          </b-dropdown-item>
                      </b-dropdown>
                  </template>

                  <!-- Other -->
                  <!--    Other    -->
                  <template #cell()="row">
                      <table-default-cell
                              :field="row.field"
                              :value="row.value"
                      />
                  </template>

                  <!--      Empty      -->
                  <template #empty>
                      <empty-content />
                  </template>

                  <!--      Table Busy      -->
                  <template #table-busy>
                      <div class="text-center text-danger my-2">
                          <b-spinner
                                  class="align-middle"
                                  variant="primary"
                          />
                      </div>
                  </template>
              </b-table>
          </template>
      </sw-table>

      <mass-update-modal :contacts="massUpdateContacts" :show-modal="showMassUpdate" @close-modal="closeMassUpdateModal" />
  </div>
</template>

<script>
import { BPopover, BTable } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'
import { GET_USER_CONTACTS } from '@/@constants/mutations'
import { contactPositionTypeByValue } from '@/@types/contactPositionTypes'
import { formatDate } from '@core/utils/filter'
import MassUpdateModal from '@/pages/contact.old/components/MassUpdateModal.vue'

export default {
  components: {
    MassUpdateModal,
    BTable,
    BPopover,
  },
  computed: {
    ...mapGetters({
      contacts: 'contacts/getContacts',
      total: 'contacts/getTotalRecords',
      currentUser: 'auth/getCurrentUser',
    }),
    fields() {
      return this.userFields.filter(field => field.visible)
    },
  },
  watch: {
    selectedContacts: {
      handler() {
        this.selectAll = this.selectedContacts.length === this.contacts.length

        this.$emit('select-contacts', this.selectedContacts)
      },
      deep: true,
    },
    showMassUpdate(newValue) {
      if (newValue) {
        this.getMassUpdateContacts()
      }
    },
    pagination: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          // eslint-disable-next-line vue/no-mutating-props
          this.$emit('setPagination', this.pagination)
          this.getUsers()
        })
      },
    },
    reloading() {
      this.getUsers()
    },
  },
  props: {
    additionalFieldsFilters: {
      required: false,
      default: () => [],
      type: Array,
    },
    propsUser: {
      type: Object,
      required: false,
      default: null,
    },
    reloading: {
      required: false,
      default: null,
    },
    perPage: {
      required: false,
      default: null,
    },
    filters: {
      required: false,
      default: null,
    },
    showMassUpdate: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  emits: ['changeAssignedUsers', 'setPagination'],
  data: () => ({
    selectedContacts: [],
    loading: false,
    selectAll: false,
    showMassUpdateModal: false,
    searchQuery: '',
    userFields: [
      {
        key: 'select', label: 'Select', visible: true,
      },
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { key: 'createdAt', label: 'CreatedAt', visible: true },
      { key: 'name', label: 'FirstNameAndLastName', visible: true },
      { key: 'phone', label: 'contact.phone', visible: true },
      { key: 'email', label: 'Email', visible: true },
      { key: 'agreeMarketing', label: 'contact.agreeMarketing', visible: true },
      {
        key: 'contactAssignedUsers', label: 'Users', visible: true, tag: 'avatar', props: { itemsField: 'user' },
      },
      { key: 'status', label: 'task.Status', visible: true },
      { key: 'contactTypes', label: 'Tags', visible: true },
      {
        key: 'createdBy', label: 'contact.createdBy', visible: true, tag: 'avatar',
      },
      { key: 'position', label: 'ContactPosition', visible: true },
      { key: 'organization', label: 'Organization', visible: true },
      { key: 'vatId', label: 'contact.vatId', visible: true },
      { key: 'address', label: 'Address', visible: true },
      { key: 'addressCorrespondence', label: 'AddressForCorrespondence', visible: true },
      {
        key: 'action', label: 'Action', visible: true,
      },
    ],
    // Pagination
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },

    massUpdateContacts: [],
  }),
  mounted() {
    // eslint-disable-next-line vue/no-mutating-props
    this.$emit('setPagination', this.pagination)
    if (this.propsUser) {
      this.changeAssignedUsers([this.propsUser])
    } else if (this.propsUser === false) {
      this.changeAssignedUsers([])
    }
  },
  methods: {
    selectAllContacts() {
      this.selectedContacts = this.selectAll ? this.contacts.map(contact => contact.id) : []

      this.$emit('select-contacts', this.selectedContacts)
    },
    formatDate,
    contactPositionTypeByValue,
    changeAssignedUsers(payload) {
      this.$emit('changeAssignedUsers', payload)

      this.getUsers()
    },
    onChangeContactStatus(status, contactId, index) {
      const updateData = {
        id: contactId.toString(),
        status: status.id.toString(),
      }
      axiosIns.patch('1/contacts', updateData)
        .then(() => {
          this.showToast('success', this.$i18n.t('StatusUpdated'))
          this.$set(this.contacts[index], 'status', status)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    closeMassUpdateModal() {
      this.showMassUpdateModal = false
      this.massUpdateContacts = []
      this.$emit('changeMassUpdateState', { field: 'massUpdateLoading', value: false })
      this.$emit('changeMassUpdateState', { field: 'showMassUpdate', value: false })
      this.getUsers()
    },
    // Pagination
    onChangePage(event) {
      this.pagination.currentPage = event
      this.getUsers()
    },
    onChangeRecordsCount(event) {
      this.pagination.perPage = event
    },
    getMassUpdateContacts() {
      this.$emit('change-mass-update-state', { field: 'massUpdateLoading', value: true })

      if (this.selectedContacts.length) {
        this.massUpdateContacts = this.contacts.filter(contact => this.selectedContacts.find(c => c === contact.id))
        this.showMassUpdateModal = true
        this.$emit('change-mass-update-state', { field: 'massUpdateLoading', value: false })
      } else {
        this.$store.dispatch(`contacts/${GET_USER_CONTACTS}`, {
          filters: this.filters, search: this.searchQuery, sorter: this.sorter,
        })
          .then(res => {
            this.massUpdateContacts = res.data.items
            this.showMassUpdateModal = true
          })
          .finally(() => {
            this.$emit('change-mass-update-state', { field: 'massUpdateLoading', value: false })
          })
      }
    },
    getUsers() {
      this.loading = true
      this.$nextTick(() => {
        const { pagination } = this

        this.$store.dispatch(`contacts/${GET_USER_CONTACTS}`, {
          filters: this.filters, pagination, search: this.searchQuery, sorter: this.sorter, additionalFieldsFilters: this.additionalFieldsFilters,
        })
          .then(res => {
            this.pagination.totalRecords = res.data.totalItemCount
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            // eslint-disable-next-line no-prototype-builtins
            if (err.hasOwnProperty('message')) {
              return
            }

            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      })
    },
  },
}
</script>
