var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sw-table',{attrs:{"table-id":"87f2aa6a20f2484a8054a68561d07ba1","show-columns":"","show-search":"","fields":_vm.userFields,"pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"change-search-query":function($event){_vm.searchQuery = $event},"reload-content":_vm.getUsers,"set-fields":function($event){_vm.userFields = $event}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"selectableTable",staticClass:"table-wrap-words",attrs:{"items":_vm.contacts,"fields":_vm.fields,"sort-by":_vm.sorter.sortBy,"sort-desc":_vm.sorter.sortDesc,"no-local-sorting":true,"striped":"","responsive":"","busy":_vm.loading,"show-empty":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sorter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sorter, "sortDesc", $event)},"sort-changed":function($event){Object.assign(_vm.sorter, $event); _vm.getUsers()}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"head(select)",fn:function(ref){
var label = ref.label;
return [_c('b-form-checkbox',{on:{"change":_vm.selectAllContacts},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})]}},{key:"cell(select)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"value":item.id},model:{value:(_vm.selectedContacts),callback:function ($$v) {_vm.selectedContacts=$$v},expression:"selectedContacts"}})]}},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_vm._v(" #"+_vm._s(_vm.getOrderNo(index, _vm.pagination, _vm.sorter))+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.firstName || item.lastName)?_c('router-link',{staticClass:"text-nowrap",class:{ 'deleted-text': item.deletedAt },attrs:{"to":("/contact/" + (item.id))}},[_vm._v(" "+_vm._s(_vm.getUserName(item))+" ")]):(item.id)?_c('router-link',{class:{ 'deleted-text': item.deletedAt },attrs:{"to":("/contact/" + (item.id))}},[_c('feather-icon',{attrs:{"icon":"MinuIcon"}})],1):_c('feather-icon',{attrs:{"icon":"MinuIcon"}})]}},{key:"cell(address)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.getAddress(item))+" ")])]}},{key:"cell(addressCorrespondence)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.getAddress(item, 'correspondence'))+" ")])]}},{key:"cell(phone)",fn:function(ref){
var value = ref.value;
return [(value)?_c('a',{staticClass:"text-nowrap",attrs:{"href":("tel:" + value)}},[_vm._v(" "+_vm._s(_vm._f("phoneFormat")(value))+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(email)",fn:function(ref){
var value = ref.value;
return [(value)?_c('a',{staticClass:"text-nowrap",attrs:{"href":("mailto:" + value)}},[_vm._v(" "+_vm._s(value)+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(contactTypes)",fn:function(ref){
var value = ref.value;
var index = ref.index;
return [(Array.isArray(value) && value.length)?_c('div',{staticClass:"mt-25"},_vm._l((value),function(tag){return _c('b-badge',{key:("tag_" + (tag.id) + "_" + index),staticClass:"mr-25",attrs:{"variant":"light-secondary"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"color-dot mr-25",staticStyle:{"border":"1px solid white"},style:({ backgroundColor: tag.color || '#fff' })}),_vm._v(" "+_vm._s(tag.name)+" ")])])}),1):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(position)",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.contactPositionTypeByValue(value)))+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(vatId)",fn:function(ref){
var item = ref.item;
return [(item.organization && item.organization.vatId)?_c('span',[_vm._v(" "+_vm._s(item.organization.vatId)+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
var index = ref.index;
var item = ref.item;
return [_c('b-badge',{staticClass:"cursor-pointer",style:({ backgroundColor: value && value.color ? value.color + ' !important' : '#fff' }),attrs:{"id":("status_row_" + index)}},[_vm._v(" "+_vm._s(value && value.name ? value.name : '-')+" ")]),(_vm.checkRequiredViewPermissions([_vm.$viewRanks.SHOW_CONTACT_STATUS_EDIT]))?_c('b-popover',{attrs:{"triggers":"hover","target":("status_row_" + index),"container":"customizedTable"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('funnel.ChangeStatus'))+" ")])]},proxy:true}],null,true)},[_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.$store.getters['typeSettings/geContactStatuses']),function(status){return _c('b-list-group-item',{key:("status__" + (status.id) + "_" + index),staticClass:"cursor-pointer",attrs:{"disabled":status.id === item.status.id},on:{"click":function($event){return _vm.onChangeContactStatus(status, item.id, index)}}},[_c('div',{staticClass:"color-dot",style:({backgroundColor: status.color})}),_vm._v(" "+_vm._s(status.name)+" ")])}),1)],1):_vm._e()]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [(_vm.checkRequiredPermissions([_vm.$roles.CONTACT_EDIT_DETAILS, _vm.$roles.CONTACT_DELETE]))?_c('b-dropdown',{attrs:{"id":"dropdown-dropleft","dropleft":"","no-caret":"","variant":"flat"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[(_vm.checkRequiredPermissions([_vm.$roles.CONTACT_EDIT_DETAILS]))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push(("/contacts/edit/" + (item.id)))}}},[_c('dropdown-item-label',{attrs:{"icon":"EditIcon","label":_vm.$t('Edit')}})],1):_vm._e(),(_vm.checkRequiredPermissions([_vm.$roles.CONTACT_DELETE]))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('delete-item', item.id)}}},[_c('dropdown-item-label',{attrs:{"icon":"TrashIcon","label":_vm.$t('Delete')}})],1):_vm._e()],1):_vm._e()]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])}),_c('mass-update-modal',{attrs:{"contacts":_vm.massUpdateContacts,"show-modal":_vm.showMassUpdate},on:{"close-modal":_vm.closeMassUpdateModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }